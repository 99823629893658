import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import News from './News';
import Navbar from './Navbar';
import Footer from './Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Home = () => {

    const [items, setItems] = useState([]);
    const axiosInstance = axios.create({baseURL : process.env.REACT_APP_API_URL})
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('/');
          setItems(response.data);
        } catch (error) {
          console.error('Failed to fetch items: ' + error.message);
        }
      };
  
      fetchData();
    }, []);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axiosInstance.get('/posts/details'); 
                setPosts(response.data.posts);
            } catch (err) {
                console.error('Error fetching posts:', err);
            }
        };

        fetchPosts();
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === posts.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? posts.length - 1 : prevIndex - 1
      );
    };

    
    return (
        <>
       
     <Navbar/>
     
<section id="hero" class="hero section">
  <div class="container" data-aos="fade-up" data-aos-delay="100">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="hero-content" data-aos="fade-up" data-aos-delay="200">
          <div class="company-badge mb-4">
            <i class="bi bi-gear-fill me-2"></i>
            Transforming Industries Through AI Excellence
          </div>
          <h1 class="mb-4">
            Advanced Learning <br />
            Solutions by <br />
            <span class="accent-text">ADVISIONS LMS</span>
          </h1>
          <p class="mb-4 mb-md-5">
            Empowering organizations, schools, and universities with cutting-edge Learning Management Systems for STEM, AI, Robotics, IoT, and more.
          </p>
          <div class="hero-buttons">
            <a href="/lmsDetails" class="btn btn-primary me-0 me-sm-2 mx-1">Explore LMS</a>
            <a href="https://www.youtube.com/watch?v=YourVideoLink" class="btn btn-link mt-2 mt-sm-0 glightbox">
              <i class="bi bi-play-circle me-1"></i>
              Watch Overview
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="hero-image" data-aos="zoom-out" data-aos-delay="300">
          <img src="https://piogroup.net/uploads/editor/img-learning-management-system-infographic%20(1)%20(1)-65134a35841d5.png" alt="Hero Image" class="img-fluid" />
          <div class="customers-badge">
            <div class="customer-avatars">
              <img src="/img/avatar-1.webp" alt="Customer 1" class="avatar" />
              <img src="/img/avatar-2.webp" alt="Customer 2" class="avatar" />
              <img src="/img/avatar-3.webp" alt="Customer 3" class="avatar" />
              <img src="/img/avatar-4.webp" alt="Customer 4" class="avatar" />
              <img src="/img/avatar-5.webp" alt="Customer 5" class="avatar" />
            </div>
            <p class="mb-0 mt-2">20,000+ users benefiting from our LMS solutions worldwide.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row stats-row gy-4 mt-5" data-aos="fade-up" data-aos-delay="500">
      <div class="col-lg-3 col-md-6">
        <div class="stat-item">
          <div class="stat-icon">
            <i class="bi bi-trophy"></i>
          </div>
          <div class="stat-content">
            <h4>200+ Institutions</h4>
            <p class="mb-0">Powered by Advisions LMS</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stat-item">
          <div class="stat-icon">
            <i class="bi bi-briefcase"></i>
          </div>
          <div class="stat-content">
            <h4>20k Learners</h4>
            <p class="mb-0">Gaining future-ready skills</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stat-item">
          <div class="stat-icon">
            <i class="bi bi-graph-up"></i>
          </div>
          <div class="stat-content">
            <h4>1,000+ Courses</h4>
            <p class="mb-0">Available on the platform</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="stat-item">
          <div class="stat-icon">
            <i class="bi bi-award"></i>
          </div>
          <div class="stat-content">
            <h4>Awards & Recognition</h4>
            <p class="mb-0">Acknowledged for innovation in education</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="about" class="about section m-8">
  <div class="container p-4" data-aos="fade-up" data-aos-delay="100">
    <div class="row gy-4 align-items-center justify-content-between">
      <div class="col-xl-5" data-aos="fade-up" data-aos-delay="200">
        <span class="about-meta">ABOUT ADVISIONS</span>
        <h2 class="about-title">Driving Innovation in Learning</h2>
        <p class="about-description">
          At Advisions R&D, we specialize in creating next-generation AI-powered Learning Management Systems tailored to the needs of schools, universities, and corporates. With solutions like adaptive learning, real-time analytics, and secure integrations, we redefine modern education.
        </p>
        <div class="row feature-list-wrapper">
          <div class="col-md-6">
            <ul class="feature-list">
              <li><i class="bi bi-check-circle-fill"></i> AI-Driven Learning</li>
              <li><i class="bi bi-check-circle-fill"></i> Integrated STEM Curriculum</li>
              <li><i class="bi bi-check-circle-fill"></i> Advanced Analytics</li>
            </ul>
          </div>
          <div class="col-md-6">
            <ul class="feature-list">
              <li><i class="bi bi-check-circle-fill"></i> Secure Cloud Infrastructure</li>
              <li><i class="bi bi-check-circle-fill"></i> Collaborative Tools</li>
              <li><i class="bi bi-check-circle-fill"></i> Multi-Device Accessibility</li>
            </ul>
          </div>
        </div>
        <div class="info-wrapper">
          <div class="row gy-4">
            <div class="col-lg-5">
              <div class="profile d-flex align-items-center gap-3">
              <img src="/assets/img/santosh.jpeg" alt="CEO Profile" class="profile-image"/>
                <div>
                  <h4 class="profile-name">Mr. Santosh Kumar Singh</h4>
                  <p class="profile-position">CEO &amp; Founder</p>
                </div>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="contact-info d-flex align-items-center gap-2">
                <i class="bi bi-telephone-fill"></i>
                <div>
                  <p class="contact-label">Call us anytime</p>
                  <p class="contact-number">+91-8810316395</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6" data-aos="fade-up" data-aos-delay="300">
        <div class="image-wrapper">
          <div class="images position-relative" data-aos="zoom-out" data-aos-delay="400">
            <img src="https://www.iitms.co.in/images/Learning-management-system-info.png" alt="LMS Features" class="img-fluid main-image rounded-4" />

          </div>
          <div class="experience-badge floating">
            <h3>15+ <span>Years</span></h3>
            <p>Experience in educational technology</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="features-cards" class="features-cards section">
  <div class="container">
    <div class="row gy-4">
   
      <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
        <div class="feature-box orange">
          <i class="bi bi-laptop"></i>
          <h4>Interactive Courses</h4>
          <p>Engage with dynamic, hands-on content across programming, data science, and AI domains.</p>
        </div>
      </div>

     
      <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
        <div class="feature-box blue">
          <i class="bi bi-bookmark-check"></i>
          <h4>Industry-Recognized Certifications</h4>
          <p>Earn certifications from leading authorities, boosting your credentials in tech industries.</p>
        </div>
      </div>

     
      <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
        <div class="feature-box green">
          <i class="bi bi-lightbulb"></i>
          <h4>Practical Learning</h4>
          <p>Master real-world skills through project-based learning and solve challenges with innovation.</p>
        </div>
      </div>

     
      <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
        <div class="feature-box red">
          <i class="bi bi-person-circle"></i>
          <h4>Personalized Dashboard</h4>
          <p>Track your progress, access learning materials, and plan your journey through a custom dashboard.</p>
        </div>
      </div>
    </div>
  </div>
</section>



         
            <a className=" py-5 d-block bg-primary">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md10"
      ><h2 className="text-white">Let's Get Started</h2></div>
    </div>
  </div>  
</a>


<section class="py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center mt-4">
      <div class="col-12">
        <h2 class="mb-4 display-5 text-center">Courses to Empower Future Tech Leaders</h2>
        <p class="text-secondary m-4 mb-5 text-center">
          Dive into our specially curated courses to master the most in-demand technologies. 
          Build skills in programming, web development, data analysis, and beyond.
        </p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-5 gx-md-5 justify-content-center">
    
      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
        <i class="fab fa-html5"></i>
      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">HTML & CSS Fundamentals</h4>
            <p class="mb-3 text-secondary">
              Master the art of building visually stunning and responsive websites using HTML and CSS. Learn design principles and best practices.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

   
      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
          <i class="fas fa-chart-bar"></i>

      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">Data Science with Python</h4>
            <p class="mb-3 text-secondary">
              Explore data analysis, visualization, and machine learning using Python. Gain hands-on experience with real-world datasets.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

   
      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
          <i class="fab fa-python"></i>

      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">Python Web Development</h4>
            <p class="mb-3 text-secondary">
              Learn to build dynamic web applications using Django and Flask frameworks. Get insights into backend development and APIs.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
          <i class="fas fa-mobile-alt"></i>

      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">Responsive Website Design</h4>
            <p class="mb-3 text-secondary">
              Learn to create mobile-first, responsive websites using Bootstrap and advanced CSS techniques. Build projects that look great on all devices.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      
      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
          <i class="fas fa-brain"></i>

      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">AI and Machine Learning</h4>
            <p class="mb-3 text-secondary">
              Build intelligent systems with Python. Learn core AI concepts and implement machine learning algorithms to solve complex problems.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="col-10 col-md-5 col-xl-4 overflow-hidden">
        <div class="row gy-4">
          <div class="col-12 col-lg-2">
          <div class="icon-wrapper d-flex align-items-center justify-content-center rounded-circle  text-white">
          <i class="fab fa-react"></i>

      </div>
          </div>
          <div class="col-12 col-lg-10">
            <h4 class="mb-3">React.js Development</h4>
            <p class="mb-3 text-secondary">
              Dive into modern frontend development with React.js. Build interactive user interfaces and dynamic single-page applications.
            </p>
            <a href="/Courses1" class="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="site-section light-background" id="work-section">
  <div className="container">
    <div className="carousel-container">
      <h3 className="text-dark mb-4 text-center">
        IMAGE GALLERY SHOWCASING OUR WORK
      </h3>

      <div className="carousel position-relative overflow-hidden">
        <div
          className="carousel-track d-flex"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`, // Adjust transform for responsiveness
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {posts.map((slide, index) => (
            <div
              className="carousel-slide"
              key={index}
              style={{
                flex: `0 0 ${100 / (window.innerWidth >= 992 ? 3 : window.innerWidth >= 768 ? 2 : 1)}%`,
                maxWidth: `${100 / (window.innerWidth >= 992 ? 3 : window.innerWidth >= 768 ? 2 : 1)}%`,
              }}

            >
              <div className="card h-100 mx-2">
                <img
                  src={slide.imageUrl}
                  alt={slide.title}
                  className="img-fluid rounded shadow"
                />
                <div className="m-4">
                  <h5 className="card-title">{slide.title}</h5>
                  <p className="card-text">{slide.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button className="carousel-btn prev" onClick={prevSlide}>
        ❮
      </button>
      <button className="carousel-btn next" onClick={nextSlide}>
        ❯
      </button>
    </div>

    <div className="d-flex justify-content-end align-items-end mb-4">
      <a href="/galleryPage" className="text-primary font-weight-bold mt-4">
        View Details <i className="fas fa-arrow-right ml-2"></i>
      </a>
    </div>
  </div>
</section>


<section class="bg-light py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 ">
        <h3 class="fs-6 mb-2 text-secondary text-center text-uppercase">Our Expertise</h3>
        <h2 class="display-5 mb-5 text-center">We excel in providing top-notch skills for your success.</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-xl-0">
  
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-code text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.719 3.858a.5.5 0 0 0-.638-.058L.58 7.42a.5.5 0 0 0 0 .758l4.5 3.5a.5.5 0 1 0 .638-.766L1.545 8 5.719 4.624a.5.5 0 0 0 .058-.766ZM10.281 3.858a.5.5 0 0 1 .638-.058l4.5 3.5a.5.5 0 0 1 0 .758l-4.5 3.5a.5.5 0 1 1-.638-.766L14.455 8 10.281 4.624a.5.5 0 0 1-.058-.766Z" />
              <path d="M6.854 11.146a.5.5 0 0 1 0 .708l-1 1a.5.5 0 0 1-.708-.708l1-1a.5.5 0 0 1 .708 0ZM9.146 4.854a.5.5 0 0 1 0-.708l1-1a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0Z" />
            </svg>
            <h4 class="mb-4">Programming</h4>
            <p class="mb-4 text-secondary">Expertise in modern programming languages like JavaScript, Python, and Java to build innovative solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-bar-chart text-primary mb-4" viewBox="0 0 16 16">
              <path d="M0 0h1v15h15v1H0V0Zm10 5h2v8h-2V5ZM6 8h2v5H6V8ZM2 11h2v2H2v-2Z" />
            </svg>
            <h4 class="mb-4">Critical Thinking</h4>
            <p class="mb-4 text-secondary">Develop expertise in tackling multifaceted difficult problems with structured IoT-based solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-brush text-primary mb-4" viewBox="0 0 16 16">
              <path d="M15.825.14c-.646-.646-2.313.38-4.1 2.168-.272.271-.516.539-.733.802-1.62-1.273-3.553-1.504-4.91-.148-1.19 1.19-.97 3.219.242 4.968L.563 12.512a.25.25 0 0 0-.045.253c.302.756.548 1.374.677 1.673.143.33.347.56.58.663.244.107.521.101.92-.013.308-.09.654-.229 1.024-.403a.255.255 0 0 0 .09-.378L7.392 7.6c1.75 1.21 3.777 1.432 4.969.241 1.356-1.356 1.125-3.289-.148-4.91.263-.217.53-.46.802-.732 1.787-1.788 2.813-3.454 2.168-4.1ZM2.507 13.934c-.244.084-.468.152-.664.204.109-.196.2-.42.285-.657l.037-.1c.049-.133.097-.265.145-.388l.197.197c-.46.248-.592.324-.637.344ZM1.05 15h-.03.03Zm.005-.001.016.001H1.05Zm.022.002h-.023.023Z" />
            </svg>
            <h4 class="mb-4">Creative Design</h4>
            <p class="mb-4 text-secondary">Delivering stunning and user-centric designs for web, mobile, and print media that leave a lasting impression.</p>
          </div>
        </div>
      </div>
    
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-shield-lock text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.072 11.293a.5.5 0 0 0 .708-.707 3 3 0 1 1 4.24 0 .5.5 0 1 0 .708.707 4 4 0 1 0-5.656 0ZM8 4a2 2 0 0 1 1.716 3.008A2.99 2.99 0 0 1 8 6a2.99 2.99 0 0 1-1.716.992A2 2 0 0 1 8 4Z" />
              <path d="M8 0c-.69 0-1.382.04-2.073.115C4.224.26 3.443.525 2.854.9a4.001 4.001 0 0 0-1.878 3.053C.497 5.98 0 8.013 0 10.124c0 4.124 4.167 5.907 8 5.907s8-1.783 8-5.907c0-2.11-.497-4.144-1.975-6.171A4.001 4.001 0 0 0 13.146.9c-.59-.375-1.37-.64-2.073-.785A25.362 25.362 0 0 0 8 0Zm.52 11.743a3.522 3.522 0 0 1-1.04 0 3.496 3.496 0 0 1-.52-6.925V2.757a5.977 5.977 0 0 1 1.56 0v2.061a3.497 3.497 0 0 1-.52 6.925Z" />
            </svg>
            <h4 class="mb-4">Cybersecurity</h4>
            <p class="mb-4 text-secondary">Ensuring secure systems by implementing best practices in cybersecurity and vulnerability management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  
  
      <section id="call-to-action-2" className="call-to-action-2 section dark-background">
    <div className="container">
      <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
        <div className="col-xl-10">
          <div className="text-center">
            <h3>Empower Young Minds with AdvisionsLab</h3>
            <p>
              Join the movement to foster innovation and creativity in the next generation. 
              AdvisionsLab equips students with essential skills in robotics, AI, and STEM fields, 
              preparing them for a bright future in technology and beyond.
            </p>
            <a className="cta-btn" href="/Courses1">
              Our Products
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
           <Footer/>
            
        </>
    )
}

export default Home
